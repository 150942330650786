import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux'
import { ClickOutside } from 'reusable-react-components'
import { CheckboxGroup } from '../'
import { customMessage } from '../../../common/container/Status/logic'

class MultiSelectDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
      inputText: '',
      active: this.props.active,
      options: this.props.options
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active && nextProps.onSubmit) {
      this.setState({
        active: JSON.parse(JSON.stringify(nextProps.active))
      })
    }
    if (this.props.options !== nextProps.options) {
      this.setState({
        options: JSON.parse(JSON.stringify(nextProps.options))
      })
    }
    if (this.state.inputText === '' && nextProps.isLocalSearch) {
      this.setState({
        options: JSON.parse(JSON.stringify(nextProps.options))
      })
    }
  }
  onToggle() {
    this.setState({
      open: !this.state.open
    })
  }
  onSubmit() {
    this.setState({
      open: false,
      inputText: ''
    }, () => this.props.onSubmit(this.state.active))
  }
  onReset() {
    this.setState({
      open: false,
      inputText: ''
    }, () => this.props.onReset())
  }
  onChange(e, value = []) {
    e.stopPropagation()
    if (value.length > this.props.maxSelect && this.props.maxSelect !== -1) {
      if (this.props.onMaxSelect) {
        this.props.onMaxSelect()
        this.props.customMessage({
          message: 'You can\'t select more than 10 items in filter',
          status: 'error',
          title: 'Error'
        })
      }
    } else if (value.length < this.props.minSelect && this.props.minSelect !== -1 && !(this.props.onSubmit || this.props.onReset)) {
      if (this.props.onMaxSelect) {
        this.props.onMinSelect()
        this.props.customMessage({
          message: `At least of ${this.props.minSelect} should be selected`,
          status: 'error',
          title: 'Error'
        })
      }
    } else if (this.props.onSubmit) {
      let options = [...this.state.options]
      value.forEach((item) => {
        if (options.indexOf(item) === -1) {
          options = [item, ...options]
        }
      })
      this.setState({
        active: value,
        options
      })
    } else if (this.props.isLocalSearch) {
      this.setState({
        active: value,
      }, () => {
        this.props.onChange(value)
      })
    } else {
      this.props.onChange(value)
    }
  }
  onTextChange(e, value) {
    this.setState({
      inputText: value === '' ? value : e.target.value
    }, () => {
      if (this.props.isLocalSearch) {
        const options = this.props.options.filter(item => item.name.toLocaleLowerCase().indexOf(this.state.inputText.toLocaleLowerCase()) > -1)
        this.setState({
          options
        })
      } else if (this.state.inputText.length > this.props.minLength) {
        this.props.onSearch(this.state.inputText.trim())
      }
    })
  }
  outsideClick() {
    this.setState({
      open: false,
      inputText: '',
      options: this.props.isLocalSearch ? JSON.parse(JSON.stringify(this.props.options)) : this.state.options,
      ...(this.props.onSubmit ? { active: JSON.parse(JSON.stringify(this.props.active)) } : {})
    })
  }
  renderCheckbox() {
    return (
      <CheckboxGroup
        id={this.props.id}
        options={
          (this.state.inputText.length > this.props.minLength) && this.props.onSearch
            ? (this.props.isLocalSearch ? this.state.options : this.props.searchOptions) : this.state.options
        }
        keys={this.props.keys}
        active={this.props.onSubmit ? this.state.active : this.props.active}
        onChange={(value, e) => this.onChange(e, value)}
      />
    )
  }
  renderItems() {
    const {
      minLength, searchOptions, onSearch
    } = this.props
    const { options } = this.state
    if (this.props.loader) {
      return (
        <div className='reusable-ms-dropdown-no-data-found' style={this.props.colorW ? { color: '#000' } : {}}>
          <FormattedMessage id="Loading..." defaultMessage="Loading..." />
        </div>
      )
    }
    if (this.state.inputText.length > minLength && onSearch && searchOptions.length === 0) {
      return (
        <div className='reusable-ms-dropdown-no-data-found' style={this.props.colorW ? { color: '#000' } : {}} >
          {this.props.noSearchDataText}
        </div>
      )
    }
    if (this.state.inputText.length <= minLength && options.length === 0 && onSearch) {
      return (
        <div className='reusable-ms-dropdown-no-data-found' style={this.props.colorW ? { color: '#000' } : {}} >
          {this.props.noDataSearchText}
        </div>
      )
    }
    if (options.length === 0) {
      return (
        <div className='reusable-ms-dropdown-no-data-found' style={this.props.colorW ? { color: '#000' } : {}} >
          {this.props.noDataText}
        </div>
      )
    }
    return (
      <Fragment>
        {this.renderCheckbox()}
        <div className='disp-flex reusable-ms-button-group'>
          {this.props.onReset && this.props.onSubmit && <button onClick={() => this.onReset()} className='reusable-reset' ><FormattedMessage id="Reset" defaultMessage="Reset" /></button>}
          {this.props.onSubmit && <button onClick={() => this.onSubmit()} disabled={!this.state.active.length && !this.props.allowEmptySubmit} className='reusable-submit' ><FormattedMessage id="Apply" defaultMessage="Apply" /></button>}
        </div>
      </Fragment>
    )
  }
  renderBox() {
    const { intl } = this.props
    if (this.state.open && this.props.onSearch) {
      return (
        <div className='reusable-ms-dropdown-box-search'>
          <input autoFocus className='reusable-ms-dropdown-box-search-input' style={this.props.colorW ? { color: '#fff' } : {}} placeholder={this.props.placeholder} type='text' value={this.state.inputText} onChange={e => this.onTextChange(e)} />
          {this.state.inputText.length > 0 ? <div role='presentation' onClick={e => this.onTextChange(e, '')} className='reusable-ms-dropdown-box-clear'>&#10005;</div> : <div className='reusable-search-icon' />}
        </div>
      )
    }
    if (this.props.onSubmit) {
      const title1 = this.state.active.length > 0 ? this.state.active.length > 1 ? `${this.state.active[0]} +${this.state.active.length - 1}` : this.state.active[0] : this.props.title
      return (
        <div className='reusable-ms-dropdown-box-default' role='presentation' onClick={() => this.setState({ open: !this.state.open })} >
          <span title={intl.formatMessage({ id: title1 || 'abc', defaultMessage: title1 })} className='text-elips' >{title1}</span>
          {this.state.active.length > 0 && this.props.clear ? <div role='presentation' onClick={e => this.onChange(e)} className='reusable-ms-dropdown-box-clear'>&#10005;</div> : null}
        </div>
      )
    }
    const title = this.props.active.length > 0 ? this.props.active.length > 1 ? `${this.props.active[0]} +${this.props.active.length - 1}` : this.props.active[0] : this.props.title
    return (
      <div className='reusable-ms-dropdown-box-default' role='presentation' onClick={() => this.setState({ open: !this.state.open })} >
        <span title={intl.formatMessage({ id: title || 'abc', defaultMessage: title })} className='text-elips' >{title}</span>
        {this.props.active.length > 0 && this.props.clear ? <div role='presentation' onClick={e => this.onChange(e)} className='reusable-ms-dropdown-box-clear'>&#10005;</div> : null}
      </div>
    )
  }
  render() {
    return (
      <div className='padding-medium-right' >
        {this.props.label && <div className="filter-blk-label">{this.props.label}</div>}
        <div className='inline'>
          <ClickOutside onClickOutside={e => this.outsideClick(e)}>
            <div className='reusable-ms-dropdown' >
              <div className='reusable-ms-dropdown-box'>
                {this.renderBox()}
              </div>
              {this.state.open ? (
                <div className={this.props.onSubmit ? 'reusable-ms-dropdown-container-submit scrollbar' : 'reusable-ms-dropdown-container scrollbar'} >
                  {this.renderItems()}
                </div>
              ) : null}
            </div>
          </ClickOutside>
        </div>
      </div>
    )
  }
}
MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  active: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  open: PropTypes.bool,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  minLength: PropTypes.number,
  searchOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])),
  noSearchDataText: PropTypes.string,
  noDataText: PropTypes.string,
  noDataSearchText: PropTypes.string,
  onSubmit: PropTypes.func,
  clear: PropTypes.bool,
  onReset: PropTypes.func,
  keys: PropTypes.array,
  maxSelect: PropTypes.number,
  onMaxSelect: PropTypes.func,
  loader: PropTypes.bool,
  allowEmptySubmit: PropTypes.bool,
  label: PropTypes.string,
  isLocalSearch: PropTypes.bool,
  customMessage: PropTypes.func.isRequired,
  colorW: PropTypes.bool,
  onMinSelect: PropTypes.func,
  minSelect: PropTypes.number,
  intl: PropTypes.object.isRequired
}
MultiSelectDropdown.defaultProps = {
  open: false,
  active: [],
  onSearch: null,
  title: 'Select',
  placeholder: 'Search',
  minLength: 0,
  searchOptions: [],
  noSearchDataText: 'No search data found',
  noDataText: 'No data found',
  noDataSearchText: 'Search for options',
  onSubmit: null,
  clear: false,
  onReset: null,
  keys: ['name'],
  maxSelect: -1,
  minSelect: -1,
  onMaxSelect: () => null,
  onMinSelect: () => null,
  loader: false,
  allowEmptySubmit: false,
  label: '',
  isLocalSearch: false,
  colorW: false
}

const mapStateToProps = state => ({
  search: state.interventionFilterSearch
})

export default connect(mapStateToProps, { customMessage })(injectIntl(MultiSelectDropdown))
